<template>
  <div
    v-if="show"
    ref="createCustomer-modal"
    class="modal modal__right"
    id="createCustomer"
    tabindex="-1"
    role="dialog"
    aria-labelledby="modalLabel"
    aria-hidden="true"
    data-backdrop="static"
  >
    <div class="modal__dialog" role="document">
      <div class="modal__content">
        <div class="modal__head">
          <div class="modal__head__icon">
            <a
              data-dismiss="modal"
              id="customerFormCloseButton"
              class="modal__close modal__close--black"
              @click="clearCustomCustomer"
            >
              <ion-icon name="arrow-back-outline"></ion-icon>
            </a>
          </div>
          <h5 class="modal__head__title text--capital">Creating a Customer</h5>
        </div>
        <div class="modal__body">
          <form class="form">
            <ul id="accordion" class="mb5">
              <div class="modal__accordion">
                <li
                  data-toggle="collapse"
                  class="sidebar-right__item modal__accordion--toggle"
                  data-target="#ccgeneral"
                  aria-expanded="false"
                  aria-controls="ccgeneral"
                >
                  <h5
                    class="w-100 mb-0 d-flex align-items-center justify-content-between"
                  >
                    General
                    <ion-icon name="chevron-down-outline"></ion-icon>
                  </h5>
                </li>
                <div id="ccgeneral" class="collapse" data-parent="#accordion">
                  <div class="pt-5">
                    <div class="row">
                      <div class="col-6">
                        <div class="form__item">
                          <label class="form__label">First Name</label>
                          <input
                            type="text"
                            class="input input--right form__input form__input--lg"
                            placeholder="Enter First Name"
                            v-model="customerForm.customerFirstName"
                            v-bind:class="{
                              'input-required':
                                customerErrorForm.customerFirstName,
                            }"
                          />
                        </div>
                      </div>
                      <div class="col-6">
                        <div class="form__item">
                          <label class="form__label">Middle Name</label>
                          <input
                            type="text"
                            class="input input--right form__input form__input--lg"
                            placeholder="Enter Middle Name"
                            v-model="customerForm.customerMiddleName"
                          />
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-6">
                        <div class="form__item">
                          <label class="form__label">Last Name</label>
                          <input
                            type="text"
                            class="input input--right form__input form__input--lg"
                            placeholder="Enter Last Name"
                            v-model="customerForm.customerLastName"
                            v-bind:class="{
                              'input-required':
                                customerErrorForm.customerLastName,
                            }"
                          />
                        </div>
                      </div>
                      <div class="col-6">
                        <div class="form__item">
                          <label class="form__label">Customer Type</label>

                          <select
                            class="select--lg w-100"
                            v-model="customerForm.customerTypesKey"
                            @change="
                              getCustomFormFields(
                                'Customers',
                                $event.target.value
                              )
                            "
                            required
                          >
                            <option disabled value="null">
                              Select Customer type
                            </option>
                            <option
                              v-for="item in sortCustomerType"
                              v-bind:key="item.customerTypeKey"
                              v-bind:value="item.customerTypeKey"
                            >
                              {{ item.clientTypeName }}
                            </option>
                          </select>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-6">
                        <div
                          v-if="customerForm.uniqueCustomerId === ''"
                          class="form__item"
                        >
                          <label class="form__label">Generating ID...</label>
                        </div>
                        <div v-else class="form__item">
                          <label class="form__label">
                            ID
                            <i
                              class="bi bi-info-circle-fill"
                              data-toggle="tooltip"
                              data-placement="top"
                              style="font-size: 1.6rem"
                              title="Select a Customer type to generate an ID, The ID can be edited after it has generated by the system"
                            ></i>
                          </label>
                          <input
                            type="text"
                            class="input input--right form__input form__input--lg"
                            placeholder="ID generated"
                            :disabled="customerForm.uniqueCustomerId === null"
                            v-model="customerForm.uniqueCustomerId"
                            v-bind:class="{
                              'input-required':
                                customerErrorForm.uniqueCustomerId,
                            }"
                          />
                        </div>
                      </div>
                      <div class="col-6">
                        <div v-if="isDeleting" class="form__item">
                          <label class="form__label">Validating BVN...</label>
                        </div>
                        <div
                          class="form__item"
                          v-else
                          style="margin-bottom: 2rem !important"
                        >
                          <div style="padding: 0px !important" class="col-12">
                            <label
                              style="padding: 0px !important"
                              class="form__label col-4"
                              >BVN</label
                            >
                            <label
                              v-if="requiredBVN"
                              style="padding: 0px !important"
                              class="form__label col-8 text-danger"
                              >BVN is required</label
                            >
                          </div>
                          <input
                            type="text"
                            class="input input--right form__input form__input--lg"
                            placeholder="Enter Bvn"
                            @input="limitBVNEntry"
                            @keyup="validateBVNRequest"
                            v-model="customerForm.bvn"
                            v-bind:class="{
                              'input-required': customerErrorForm.bvn,
                            }"
                          />
                        </div>
                        <transition name="slide" type="animation">
                          <div class="container" v-if="bvnInfo">
                            <div class="card">
                              <div class="title">
                                <h1>
                                  {{
                                    `${bvnInfo.firstname} ${bvnInfo.middlename} ${bvnInfo.lastname}`
                                  }}
                                </h1>
                                <h2>{{ bvnInfo.bvn }}</h2>
                              </div>
                              <div class="content">
                                <div class="social">
                                  <p>DOB: {{ bvnInfo.dateOfBirth | moment }}</p>
                                </div>
                                <div class="social">
                                  <p>Email: {{ bvnInfo.email ?? "" }}</p>
                                </div>
                                <div class="social">
                                  <p>Phone: {{ bvnInfo.mobileNumber }}</p>
                                </div>
                                <div class="social">
                                  <p>Gender: {{ bvnInfo.gender }}</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </transition>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="modal__accordion">
                <li
                  data-toggle="collapse"
                  class="sidebar-right__item modal__accordion--toggle"
                  data-target="#ccdetails"
                  aria-expanded="false"
                  aria-controls="ccdetails"
                >
                  <h5
                    class="w-100 mb-0 d-flex align-items-center justify-content-between"
                  >
                    Details
                    <ion-icon name="chevron-down-outline"></ion-icon>
                  </h5>
                </li>
                <div id="ccdetails" class="collapse" data-parent="#accordion">
                  <div class="pt-5">
                    <div class="row">
                      <div class="col-6">
                        <div class="form__item">
                          <label class="form__label">Date of Birth</label>
                          <input
                            type="date"
                            class="input input--right form__input form__input--lg"
                            placeholder="Enter Date of Birth"
                            :max="currDate"
                            v-model="customerForm.customerBirthDate"
                            v-bind:class="{
                              'input-required':
                                customerErrorForm.customerBirthDate,
                            }"
                          />
                        </div>
                      </div>
                      <div class="col-6">
                        <div class="form__item">
                          <label class="form__label">Gender</label>
                          <select
                            class="select select--lg"
                            v-model="customerForm.customerGender"
                            v-bind:class="{
                              'input-required':
                                customerErrorForm.customerGender,
                            }"
                          >
                            <option value="null" disabled>Select Gender</option>
                            <option value="Male">Male</option>
                            <option value="Female">Female</option>
                          </select>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-6">
                        <div class="form__item">
                          <label class="form__label">Mobile Phone</label>
                          <input
                            type="text"
                            class="input input--right form__input form__input--lg"
                            placeholder="Enter Mobile Phone"
                            v-model="customerForm.customerMobilePhone1"
                            v-bind:class="{
                              'input-required':
                                customerErrorForm.customerMobilePhone1,
                            }"
                          />
                        </div>
                      </div>
                      <div class="col-6">
                        <div class="form__item">
                          <label class="form__label">Home Phone</label>
                          <input
                            type="text"
                            class="input input--right form__input form__input--lg"
                            placeholder=""
                            v-model="customerForm.customerHomePhone"
                          />
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-6">
                        <div class="form__item">
                          <!-- <label class="form__label">Email address</label> -->
                          <div style="padding: 0px !important" class="col-12">
                            <label
                              style="padding: 0px !important"
                              class="form__label col-4"
                              >Email</label
                            >
                            <label
                              v-if="requiredEmail"
                              style="padding: 0px !important"
                              class="form__label col-8 text-danger"
                              >Email is required</label
                            >
                          </div>
                          <input
                            type="email"
                            required
                            class="input input--right form__input form__input--lg"
                            placeholder="Enter email address"
                            v-model="customerForm.customerEmailAddress"
                            @input="
                              validateEmailCustomer(
                                customerForm.customerEmailAddress
                              )
                            "
                            v-bind:class="{
                              'input-required':
                                customerErrorForm.customerEmailAddress,
                            }"
                          />
                          <p
                            class="text--danger"
                            v-if="customerErrorForm.customerEmailAddress"
                          >
                            Please provide a valid email
                          </p>
                        </div>
                      </div>
                      <div class="col-6">
                        <div class="form__item">
                          <label class="form__label">Mobile Phone2</label>
                          <input
                            type="text"
                            class="input input--right form__input form__input--lg"
                            placeholder="Enter Mobile Phone2"
                            v-model="customerForm.customerMobilePhone2"
                          />
                        </div>
                      </div>
                    </div>
                    <!-- <div class="row">
                      <div class="col-12">
                        <div class="form__item">
                          <label class="form__label">Home Address</label>
                          <input
                            type="text"
                            class="
                              input input--right
                              form__input form__input--lg
                            "
                            placeholder="Enter Home Address"
                            v-model="customerForm.customerHomeAddress"
                          />
                        </div>
                      </div>
                    </div> -->
                  </div>
                </div>
              </div>
              <div class="modal__accordion">
                <li
                  data-toggle="collapse"
                  class="sidebar-right__item modal__accordion--toggle"
                  data-target="#cchomeaddresssection"
                  aria-expanded="false"
                  aria-controls="cchomeaddresssection"
                >
                  <h5
                    class="w-100 mb-0 d-flex align-items-center justify-content-between"
                  >
                    Home Address Section
                    <ion-icon name="chevron-down-outline"></ion-icon>
                  </h5>
                </li>
                <div
                  id="cchomeaddresssection"
                  class="collapse"
                  data-parent="#accordion"
                >
                  <div class="pt-5">
                    <div class="row">
                      <div class="col-6">
                        <div class="form__item">
                          <label class="form__label">City</label>
                          <input
                            type="text"
                            class="input input--right form__input form__input--lg"
                            placeholder="Enter City"
                            v-model="customerForm.city"
                            v-bind:class="{
                              'input-required': customerErrorForm.city,
                            }"
                          />
                        </div>
                      </div>
                      <div class="col-6">
                        <div class="form__item">
                          <label class="form__label"
                            >State/Province/Region</label
                          >
                          <input
                            type="text"
                            class="input input--right form__input form__input--lg"
                            placeholder="Enter State/Province/Region"
                            v-model="customerForm.state"
                            v-bind:class="{
                              'input-required': customerErrorForm.state,
                            }"
                          />
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-6">
                        <div class="form__item">
                          <label class="form__label">Zip Postal Code</label>
                          <input
                            type="text"
                            class="input input--right form__input form__input--lg"
                            placeholder="Enter Zip Postal Code"
                            v-model="customerForm.zipPostalCode"
                            v-bind:class="{
                              'input-required': customerErrorForm.zipPostalCode,
                            }"
                          />
                        </div>
                      </div>
                      <div class="col-6">
                        <div class="form__item">
                          <label class="form__label">Country</label>
                          <input
                            type="text"
                            class="input input--right form__input form__input--lg"
                            placeholder="Enter Country"
                            v-model="customerForm.country"
                            v-bind:class="{
                              'input-required': customerErrorForm.country,
                            }"
                          />
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-12">
                        <div class="form__item">
                          <label class="form__label"
                            >Street Address - Line 1</label
                          >
                          <input
                            type="text"
                            class="input input--right form__input form__input--lg"
                            placeholder="Enter Street Address Line 1"
                            v-model="customerForm.streetAddressLine1"
                            v-bind:class="{
                              'input-required':
                                customerErrorForm.streetAddressLine1,
                            }"
                          />
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-12">
                        <div class="form__item">
                          <label class="form__label"
                            >Street Address - Line 2</label
                          >
                          <input
                            type="text"
                            class="input input--right form__input form__input--lg"
                            placeholder="Enter Street Address Line 2"
                            v-model="customerForm.streetAddressLine2"
                            v-bind:class="{
                              'input-required':
                                customerErrorForm.streetAddressLine2,
                            }"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="modal__accordion">
                <li
                  data-toggle="collapse"
                  class="sidebar-right__item modal__accordion--toggle"
                  data-target="#customerIdDocs"
                  aria-expanded="false"
                  aria-controls="customerIdDocs"
                >
                  <h5
                    class="w-100 mb-0 d-flex align-items-center justify-content-between"
                  >
                    Identification Documents
                    <ion-icon name="chevron-down-outline"></ion-icon>
                  </h5>
                </li>
                <div
                  id="customerIdDocs"
                  class="collapse"
                  data-parent="#accordion"
                >
                  <div
                    class="row"
                    v-for="(
                      idTemp, index
                    ) in customerForm.identificationDocuments"
                    v-bind:key="
                      idTemp.identificationDocumentTemplateKey + '' + index
                    "
                  >
                    <div class="col-md-6">
                      Document Type: {{ idTemp.documentType }}
                    </div>
                    <div class="col-md-6">
                      Issuing Authority: {{ idTemp.issuingAuthority }}
                    </div>
                    <div class="col-md-6">
                      <div class="form__item">
                        <label>Document ID</label>
                        <input
                          type="text"
                          v-model="idTemp.documentId"
                          class="input form__input form__input--lg"
                          v-bind:class="{
                            'input-required': idTemp.documentIdErr,
                          }"
                        />
                        <div class="error">
                          <span class="text-danger">
                            {{ idTemp.documentType }} document pattern is
                            {{ idTemp.documentIdTemplate }}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form__item">
                        <label>Valid Until</label>
                        <input
                          type="date"
                          :min="currDate"
                          v-model="idTemp.validUntil"
                          class="input form__input form__input--lg"
                          v-bind:class="{
                            'input-required': idTemp.validUntilErr,
                          }"
                        />
                      </div>
                    </div>

                    <div v-if="idTemp.allowAttachments" class="col-12">
                      <div class="col-md-12">
                        <div class="form__item form__item_dottedbg">
                          <div class="p-5 text--center position-relative">
                            <input
                              :id="`attachment-${index}`"
                              type="file"
                              class="input form__input--lg"
                              accept="*"
                              ref="myFiles"
                              @change="onFileChange(index)"
                              hidden
                            />

                            <button
                              class="my-2 button button--black text--500 text--white text--small text--capital"
                              @click.prevent="
                                callFileUpload(`attachment-${index}`)
                              "
                            >
                              Choose File
                            </button>
                          </div>
                          <ul class="list-group">
                            <li class="list-group-item">
                              {{ idTemp.filename }} ({{ idTemp.fileSize | kb }}
                              kb)
                            </li>
                          </ul>
                        </div>
                      </div>

                      <div
                        class="row align-items-center col-md-12"
                        v-if="idTemp.showDoc"
                      >
                        <div class="col-10">
                          <div class="form__item">
                            <label
                              @click.prevent="
                                downloadAttachment(
                                  index,
                                  idTemp.attachments[0].documentDescription
                                )
                              "
                              class="download-attach"
                              >{{
                                idTemp.attachments[0].documentOriginalFileName
                              }}</label
                            >
                          </div>
                        </div>
                        <div class="col-2">
                          <div class="form__item">
                            <button
                              class="button page__body__header__button modal__confirm__button--delete button--sm text--black w-100 text--capital"
                              @click.prevent="
                                deleteAttachment(
                                  index,
                                  idTemp.attachments[0].documentDescription
                                )
                              "
                            >
                              <span>X</span>
                            </button>
                          </div>
                        </div>
                      </div>

                      <div class="col-md-12">
                        <div class="form__item">
                          <button
                            :disabled="loading"
                            class="my-2 button button--black text--500 text--white text--small text--capital"
                            @click.prevent="uploadFile(index)"
                          >
                            {{ loading ? "Uploading..." : "Upload File" }}
                          </button>
                        </div>
                      </div>
                    </div>

                    <div class="col-12">
                      <div class="col-md-12">
                        <div class="form__item">
                          <button
                            class="button page__body__header__button ml-auto button--grey text--capital"
                            type="button"
                            @click="idTemplateRemove('Customer', index)"
                          >
                            Remove Identification Document
                            <i class="ki ki-plus icon-sm ml-3"> </i>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="pt-5">
                    <div class="form__item">
                      <label class="form__label">Select Template</label>
                      <select
                        class="select select--lg"
                        v-model="customerForm.selectedIdTemplate"
                      >
                        <option :value="null" disabled selected>
                          Select ID Doc
                        </option>
                        <option
                          v-for="item in idTemplates"
                          v-bind:key="item.identificationDocumentTemplateKey"
                          v-bind:value="item.identificationDocumentTemplateKey"
                        >
                          {{ item.documentType }}
                        </option>
                      </select>
                    </div>
                    <div class="form__item">
                      <button
                        class="button page__body__header__button ml-auto button--grey text--capital"
                        type="button"
                        @click="addIdTemplateToForm('Customer')"
                      >
                        Add Identification Document
                        <i class="ki ki-plus icon-sm ml-3"> </i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div class="modal__accordion">
                <li
                  data-toggle="collapse"
                  class="sidebar-right__item modal__accordion--toggle"
                  data-target="#ccassociated"
                  aria-expanded="false"
                  aria-controls="ccassociated"
                >
                  <h5
                    class="w-100 mb-0 d-flex align-items-center justify-content-between"
                  >
                    Associated
                    <ion-icon name="chevron-down-outline"></ion-icon>
                  </h5>
                </li>
                <div
                  id="ccassociated"
                  class="collapse"
                  data-parent="#accordion"
                >
                  <div class="pt-5">
                    <div class="row">
                      <div class="col-6">
                        <div class="form__item">
                          <label class="form__label">Branch</label>
                          <select
                            class="select--lg w-100"
                            v-model="customerForm.assignedBranchKey"
                            @change="branchSelectChange($event)"
                            v-bind:class="{
                              'input-required':
                                customerErrorForm.assignedBranchKey,
                            }"
                          >
                            <option disabled value="null">Select branch</option>
                            <option
                              v-for="item in allBranches"
                              v-bind:key="item.branchKey"
                              v-bind:value="item.branchKey"
                            >
                              {{ item.branchName }} ({{ item.branchId }})
                            </option>
                          </select>
                        </div>
                      </div>
                      <div class="col-6">
                        <div class="form__item">
                          <label class="form__label">Center</label>
                          <select
                            class="select--lg w-100"
                            v-model="customerForm.assignedCentreKey"
                            v-bind:class="{
                              'input-required':
                                customerErrorForm.assignedCentreKey,
                            }"
                          >
                            <option disabled value="null">Select Center</option>
                            <option
                              v-for="item in branchCenter"
                              v-bind:key="item.centerKey"
                              v-bind:value="item.centerKey"
                            >
                              {{ item.centerName }}
                            </option>
                          </select>
                        </div>
                      </div>
                      <div class="col-6">
                        <div class="form__item">
                          <label class="form__label">Account Officer</label>
                          <select
                            class="select--lg w-100"
                            v-model="customerForm.assignedUserKey"
                            v-bind:class="{
                              'input-required':
                                customerErrorForm.assignedUserKey,
                            }"
                          >
                            <option disabled value="null">
                              Select Account Officer
                            </option>
                            <option
                              v-for="item in accountOfficers"
                              v-bind:key="item.userKey"
                              v-bind:value="item.userKey"
                            >
                              {{ item.name }}
                            </option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="modal__accordion"
                v-for="(set, index) in customFields"
                :key="set.customFieldSetKey"
                v-show="customerForm.customerTypesKey"
              >
                <li
                  v-if="set.customFields.length > 0"
                  data-toggle="collapse"
                  class="sidebar-right__item modal__accordion--toggle"
                  :data-target="`#ccSet${index}`"
                  aria-expanded="false"
                  aria-controls="ccprofileNote"
                >
                  <h5
                    class="w-100 mb-0 d-flex align-items-center justify-content-between"
                  >
                    {{ set.customFieldSetName }}
                    <ion-icon name="chevron-down-outline"></ion-icon>
                  </h5>
                </li>
                <div
                  :id="`ccSet${index}`"
                  class="collapse"
                  data-parent="#accordion"
                >
                  <div class="pt-5">
                    <div class="row">
                      <div
                        class="col-6"
                        v-for="(form, i) in set.customFields"
                        :key="form.customFieldKey"
                        v-show="checkCustomFieldAvail(form)"
                      >
                        <div class="form__item">
                          <label class="form__label">{{
                            form.customFieldName
                          }}</label>
                          <input
                            :type="
                              form.dataType === 'Number'
                                ? 'number'
                                : form.dataType === 'Date'
                                ? 'date'
                                : form.dataType === 'Checkbox'
                                ? 'checkbox'
                                : 'text'
                            "
                            v-if="
                              form.dataType !== 'Selection' &&
                              form.dataType.toLowerCase() !== 'user link' &&
                              form.dataType.toLowerCase() !== 'group link' &&
                              form.dataType.toLowerCase() !== 'customer link'
                            "
                            @input="
                              editAccountValidateNumber(
                                form.customFieldValue,
                                form.dataType,
                                index,
                                i
                              )
                            "
                            :id="'cus-' + form.customFieldKey"
                            :ref="form.customFieldKey"
                            v-model="form.customFieldValue"
                            class="input input--right form__input form__input--lg"
                            v-bind:class="{
                              'input-required': form.customFieldNameErr,
                            }"
                            :placeholder="`Enter ${form.customFieldName}`"
                          />
                          <select
                            class="select--lg w-100"
                            v-if="
                              form.dataType === 'Selection' &&
                              form.dataType.toLowerCase() !== 'user link' &&
                              form.dataType.toLowerCase() !== 'group link' &&
                              form.dataType.toLowerCase() !== 'customer link'
                            "
                            :id="'cus-' + form.customFieldKey"
                            :ref="form.customFieldKey"
                            v-model="form.customFieldValue"
                            v-bind:class="{
                              'input-required': form.customFieldNameErr,
                            }"
                          >
                            <option disabled selected value="null">
                              Select {{ form.customFieldName }}
                            </option>
                            <option
                              v-for="opt in form.customFieldSelections"
                              :key="opt.customFieldSelectionKey"
                              :value="opt.value"
                            >
                              {{ opt.value }}
                            </option>
                          </select>
                          <!-- User Multi select -->
                          <multiselect
                            v-model="form.customFieldValue"
                            :id="'cus-' + form.customFieldKey"
                            :ref="form.customFieldKey"
                            label="name"
                            track-by="userKey"
                            placeholder="Type to search"
                            open-direction="bottom"
                            :options="sortedUsers"
                            :multiple="false"
                            :searchable="true"
                            :internal-search="true"
                            :clear-on-select="false"
                            :close-on-select="true"
                            :options-limit="300"
                            :limit="3"
                            :max-height="800"
                            :show-no-results="false"
                            :hide-selected="true"
                            v-if="form.dataType.toLowerCase() === 'user link'"
                            v-bind:class="{
                              'input-required': form.customFieldNameErr,
                            }"
                          >
                            <template
                              slot="tag"
                              slot-scope="{ option, remove }"
                            >
                              <span class="custom__tag">
                                <span>{{ option.name }}</span>
                                <span
                                  class="custom__remove"
                                  @click="remove(option)"
                                  >❌</span
                                >
                              </span>
                            </template>
                          </multiselect>

                          <!-- Group multiselect -->
                          <multiselect
                            v-model="form.customFieldValue"
                            :id="'cus-' + form.customFieldKey"
                            :ref="form.customFieldKey"
                            label="groupName"
                            track-by="groupKey"
                            placeholder="Type to search"
                            open-direction="bottom"
                            :options="sortedGroups"
                            :multiple="false"
                            :searchable="true"
                            :internal-search="true"
                            :clear-on-select="false"
                            :close-on-select="true"
                            :options-limit="300"
                            :limit="3"
                            :max-height="800"
                            :show-no-results="false"
                            :hide-selected="true"
                            v-if="form.dataType.toLowerCase() === 'group link'"
                            v-bind:class="{
                              'input-required': form.customFieldNameErr,
                            }"
                          >
                            <template
                              slot="tag"
                              slot-scope="{ option, remove }"
                            >
                              <span class="custom__tag">
                                <span>{{ option.name }}</span>
                                <span
                                  class="custom__remove"
                                  @click="remove(option)"
                                  >❌</span
                                >
                              </span>
                            </template>
                          </multiselect>

                          <!-- Customer multiselect -->
                          <multiselect
                            v-model="form.customFieldValue"
                            :id="'cus-' + form.customFieldKey"
                            :ref="form.customFieldKey"
                            label="customerFirstName"
                            track-by="customerKey"
                            placeholder="Type to search"
                            open-direction="bottom"
                            :options="sortedCustomers"
                            :multiple="false"
                            :searchable="true"
                            :internal-search="true"
                            :clear-on-select="false"
                            :close-on-select="true"
                            :options-limit="300"
                            :limit="3"
                            :max-height="800"
                            :show-no-results="false"
                            :hide-selected="true"
                            v-if="
                              form.dataType.toLowerCase() === 'customer link'
                            "
                            v-bind:class="{
                              'input-required': form.customFieldNameErr,
                            }"
                          >
                            <template
                              slot="singleLabel"
                              slot-scope="{ option }"
                            >
                              <span>{{ option.customerFirstName }}</span>
                              <span>&nbsp;{{ option.customerLastName }}</span>
                            </template>

                            <template slot="option" slot-scope="{ option }">
                              <div class="option__desc">
                                <span class="option__title">
                                  {{ option.customerFirstName }}&nbsp;
                                </span>
                                <span class="option__small">{{
                                  option.customerLastName
                                }}</span>
                              </div>
                            </template>
                          </multiselect>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="modal__accordion" v-if="requiredEmployer">
                <li
                  data-toggle="collapse"
                  class="sidebar-right__item modal__accordion--toggle"
                  data-target="#ccpEmployer"
                  aria-expanded="false"
                  aria-controls="ccpEmployer"
                >
                  <h5
                    class="w-100 mb-0 d-flex align-items-center justify-content-between"
                  >
                    Employer
                    <ion-icon name="chevron-down-outline"></ion-icon>
                  </h5>
                </li>
                <div id="ccpEmployer" class="collapse" data-parent="#accordion">
                  <div class="pt-5">
                    <div class="form__item">
                      <label
                        style="padding: 0px !important"
                        class="form__label col-8 text-danger"
                        >Employer is required</label
                      >
                      <multiselect
                        v-model="employer"
                        id="employer"
                        label="name"
                        track-by="employerKey"
                        placeholder="Select an employer"
                        selectLabel=""
                        open-direction="bottom"
                        :options="sortedEmployers"
                        :searchable="true"
                        :internal-search="true"
                        :clear-on-select="false"
                        :close-on-select="true"
                        :options-limit="300"
                        :limit="7"
                        :max-height="800"
                        :preselect-first="false"
                        @input="updateEmployer"
                      >
                        <template slot="tag" slot-scope="{ option }"
                          ><span class="custom__tag"
                            ><span>{{ option.name }}</span></span
                          ></template
                        >
                        <span slot="noResult">Oops! No result found.</span>
                      </multiselect>
                    </div>
                  </div>
                </div>
              </div>
              <div class="modal__accordion">
                <li
                  data-toggle="collapse"
                  class="sidebar-right__item modal__accordion--toggle"
                  data-target="#ccprofileNote"
                  aria-expanded="false"
                  aria-controls="ccprofileNote"
                >
                  <h5
                    class="w-100 mb-0 d-flex align-items-center justify-content-between"
                  >
                    Profile Note
                    <ion-icon name="chevron-down-outline"></ion-icon>
                  </h5>
                </li>
                <div
                  id="ccprofileNote"
                  class="collapse"
                  data-parent="#accordion"
                >
                  <div class="pt-5">
                    <div class="form__item">
                      <textarea
                        cols="5"
                        rows="5"
                        class="input form__input"
                        placeholder="Profile Note"
                        v-model="customerForm.customerNotes"
                      ></textarea>
                    </div>
                  </div>
                </div>
              </div>
            </ul>
            <div class="form__item">
              <div class="form__action">
                <button
                  type="submit"
                  class="button form__button form__button--lg w-30 save-changes"
                  @click.prevent="saveCustomerForm"
                  v-if="!isSaving"
                >
                  Save Customer
                </button>

                <!-- loader button -->
                <a
                  role="button"
                  class="button form__button form__button--lg w-25 save-changes"
                  v-if="isSaving"
                  :disabled="true"
                >
                  <div class="spinner-border text-light" role="status"></div>
                </a>
                <a
                  data-dismiss="modal"
                  role="button"
                  class="modal__close form__action__text form__action__text--danger text--capital ml6"
                  @click="clearCustomCustomer"
                  v-if="!isSaving"
                >
                  cancel
                </a>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Multiselect from "vue-multiselect";
import GeneralApiService from "@/core/services/general.service";
import customerService from "@/core/services/general-setup-service/customer.service";
import idTemplatesService from "@/core/services/general-setup-service/id-templates.service";
import moment from "moment";
import { mapState } from "vuex";
import fieldSetService from "@/core/services/fieldset-service/fieldset.service";
import { GET_CUSTOMER_TYPES } from "@/core/services/store/types";
import ApiService from "@/core/services/api.service";
import branchesService from "@/core/services/organization-service/branches.service";
import centresService from "@/core/services/organization-service/centres.service";
import AccessApiService from "@/core/services/access.service";

export default {
  name: "CustomerCreation",
  props: {
    show: Boolean,
    close: Function,
    customerTypesKey: String,
  },
  components: {
    Multiselect,
  },
  data() {
    return {
      allActiveCustomers: {},
      userLoading: false,
      allUsers: [],
      customerForm: {
        assignedBranchKey: null,
        assignedCentreKey: null,
        assignedUserKey: null,
        customerTypesKey: null,
        customerBirthDate: null,
        customerEmailAddress: null,
        customerFirstName: null,
        customerGender: null,
        customerHomePhone: null,
        uniqueCustomerId: null,
        bvn: null,
        customerLastName: null,
        customerMiddleName: null,
        customerMobilePhone1: null,
        customerMobilePhone2: null,
        customerNotes: null,
        preferredLanguage: "English",
        customFieldValues: [],
        identificationDocuments: [],
        selectedIdTemplate: null,
        hasValidateBvn: false,
        employeeKey: null,
        // customerHomeAddress: null,
        streetAddressLine1: null,
        streetAddressLine2: null,
        city: null,
        state: null,
        zipPostalCode: null,
        country: null,
      },
      customerErrorForm: {
        assignedBranchKey: false,
        assignedCentreKey: false,
        assignedUserKey: false,
        customerTypesKey: false,
        customerBirthDate: false,
        customerEmailAddress: false,
        customerFirstName: false,
        customerGender: false,
        customerHomePhone: false,
        uniqueCustomerId: false,
        bvn: false,
        customerLastName: false,
        customerMiddleName: false,
        customerMobilePhone1: false,
        customerMobilePhone2: false,
        customerNotes: false,
        preferredLanguage: false,
        employeeKey: false,
        streetAddressLine1: false,
        streetAddressLine2: false,
        city: false,
        state: false,
        zipPostalCode: false,
        country: false,
      },
      allEmployers: [],
      customFields: [],
      idTemplates: [],
      requiredBVN: false,
      requiredEmail: false,
      requiredEmployer: false,
      bvnInfo: false,
      isDeleting: false,
      isSaving: false,
      accountOfficers: [],
      allBranches: [],
      branchCenter: [],
      currDate: moment().format("YYYY-MM-DD"),
      limitValue: "",
      loading: false,
      myFiles: null,
      filename: "",
      fileSize: "",
      allCentres: [],
      internalControl: "",
    };
  },
  methods: {
    clearCustomCustomer() {
      if (this.customFields.length > 0) {
        this.customFields.forEach((formSet) => {
          formSet.customFields.forEach((form) => {
            form.customFieldValue = "";
          });
        });
      }
    },
    limitBVNEntry(event) {
      const { value } = event.target;
      if (value.length > 11) {
        this.customerForm.bvn = this.limitValue;
      } else {
        this.limitValue = value;
      }
    },
    async getAllUsers() {
      this.allUsers = [];
      try {
        this.userLoading = true;
        const userModel = {
          pageNumber: 1,
          pageSize: 1000000,
          branchKey: "",
          status: true,
          filters: [],
        };
        AccessApiService.post(
          "ProleanUser/GetUsersWithoutPermission",
          userModel
        ).then((resp) => {
          this.allUsers = resp.data.data;
          this.userLoading = false;
        });
      } catch (e) {
        const error = e.response.data.message;
        this.userLoading = false;
        this.$emit("alertError", error);
      }
    },
    async getAllCustomers() {
      this.allActiveCustomers = [];
      try {
        this.userLoading = true;
        const userModel = {
          pageIndex: 1,
          pageSize: 100000,
          filters: [],
          customerStatus: "Active",
        };
        GeneralApiService.post("Customer/customers", userModel).then((resp) => {
          this.allActiveCustomers = resp.data.data.items;
          this.userLoading = false;
        });
      } catch (e) {
        const error = e.response.data.message;
        this.userLoading = false;
        this.$emit("alertError", error);
      }
    },
    branchSelectChange(event) {
      const branchKey = event.target.value;
      this.getCustomerAccountOfficer(branchKey);
      this.getBranchCenters(branchKey);
    },
    getCustomerAccountOfficer(key) {
      this.accountOfficers = [];
      AccessApiService.get(
        `ProleanUser/GetBranchClientOfficer?branchKey=${key}`
      ).then((resp) => {
        this.accountOfficers = resp.data.data;
      });
    },
    async getBranchCenters(key) {
      const model = {
        searchParam: null,
        branchKey: key,
        pageIndex: 1,
        pageSize: 100,
      };
      try {
        const res = await centresService.search(model);
        this.branchCenter = [];
        this.branchCenter = res.data.data.items;
        // this.isLoading = false;
      } catch (e) {
        // this.isLoading = false;
        const error = e.response.data.message;
        this.$emit("alertWarning", error);
      }
    },
    getInternalControl() {
      GeneralApiService.get("InternalControls").then((resp) => {
        this.internalControl = resp.data.data;
      });
    },
    onFileChange(index) {
      this.myFiles = this.$refs.myFiles[index].files[0];
      this.customerForm.identificationDocuments[index].filename =
        this.$refs.myFiles[index].files[0].name;
      this.customerForm.identificationDocuments[index].fileSize =
        this.$refs.myFiles[index].files[0].size;
    },
    callFileUpload(id) {
      let input = document.getElementById(id);
      input.click();
    },
    uploadFile(index) {
      if (!this.customerForm.identificationDocuments[index].validUntil) {
        this.$emit("alertWarning", "Document ID expiry date is required.");
        return;
      }
      const formData = new FormData();
      formData.append("uploadFile", this.myFiles);
      this.loading = true;
      ApiService.postFormData(
        `GeneralDocument/UploadAttachmentForCustomer`,
        formData
      )
        .then((resp) => {
          let desc = "File successfully uploaded";
          this.customerForm.identificationDocuments[index].showDoc = true;
          this.customerForm.identificationDocuments[index].filename = "";
          this.customerForm.identificationDocuments[index].fileSize = 0;
          this.customerForm.identificationDocuments[index].attachments = [
            {
              documentDescription: resp.data.data.documentKey,
              documentFileSize: resp.data.data.fileSize,
              documentName: resp.data.data.fileName,
              documentOriginalFileName: resp.data.data.originalName,
              documentType: resp.data.data.fileType,
              // documentLocation: resp.data.data.fileUrl,
              documentLocation: resp.data.data.filelocation,
            },
          ];
          this.$emit("alertSuccess", desc);
          this.loading = false;
          this.filename = "";
          this.fileSize = "";
        })
        .catch((error) => {
          this.$emit("alertWarning", error.response.data.message);
          this.loading = false;
        });
    },
    validateCustomerForm() {
      this.clearCustomerValidation();

      const assignedBranchKey = this.internalControl.assignmentConstraints
        .branch
        ? this.customerForm.assignedBranchKey
        : true;
      const assignedCentreKey = this.internalControl.assignmentConstraints
        .centre
        ? this.customerForm.assignedCentreKey
        : true;
      const assignedUserKey = this.internalControl.assignmentConstraints
        .accountOfficer
        ? this.customerForm.assignedUserKey
        : true;

      if (
        this.customerForm.customerFirstName &&
        this.customerForm.customerLastName &&
        this.customerForm.uniqueCustomerId &&
        this.customerForm.customerBirthDate &&
        this.customerForm.customerGender &&
        this.customerForm.customerMobilePhone1 &&
        assignedBranchKey &&
        assignedCentreKey &&
        assignedUserKey
      ) {
        return true;
      }

      if (!this.customerForm.customerFirstName) {
        this.customerErrorForm.customerFirstName = true;
      }
      if (!this.customerForm.customerLastName) {
        this.customerErrorForm.customerLastName = true;
      }
      if (!this.customerForm.uniqueCustomerId) {
        this.customerErrorForm.uniqueCustomerId = true;
      }
      if (!this.customerForm.customerBirthDate) {
        this.customerErrorForm.customerBirthDate = true;
      }
      if (!this.customerForm.customerMobilePhone1) {
        this.customerErrorForm.customerMobilePhone1 = true;
      }
      if (!this.customerForm.customerGender) {
        this.customerErrorForm.customerGender = true;
      }
      if (!this.customerForm.customerEmailAddress) {
        this.customerErrorForm.customerEmailAddress = true;
      }
      // if (!this.customerForm.assignedBranchKey) {
      //   this.customerErrorForm.assignedBranchKey = true;
      // }
      // if (this.customerForm.assignedBranchKey) {
      //   if (!this.customerForm.assignedCentreKey) {
      //     this.customerErrorForm.assignedCentreKey = true;
      //   }
      // }
      // if (this.customerForm.assignedBranchKey) {
      //   if (!this.customerForm.assignedUserKey) {
      //     this.customerErrorForm.assignedUserKey = true;
      //   }
      // }
      if (!assignedBranchKey) {
        this.customerErrorForm.assignedBranchKey = true;
      }
      if (assignedBranchKey) {
        if (!assignedCentreKey) {
          this.customerErrorForm.assignedCentreKey = true;
        }
      }
      if (assignedBranchKey) {
        if (!assignedUserKey) {
          this.customerErrorForm.assignedUserKey = true;
        }
      }
      if (!this.customerForm.employeeKey) {
        this.customerErrorForm.employeeKey = true;
      }

      return false;
    },
    clearCustomerValidation() {
      this.customerErrorForm = {
        assignedBranchKey: false,
        assignedCentreKey: false,
        assignedUserKey: false,
        customerTypesKey: false,
        customerBirthDate: false,
        customerEmailAddress: false,
        customerFirstName: false,
        customerGender: false,
        customerHomePhone: false,
        uniqueCustomerId: false,
        bvn: false,
        customerLastName: false,
        customerMiddleName: false,
        customerMobilePhone1: false,
        customerMobilePhone2: false,
        customerNotes: false,
        preferredLanguage: false,
        employeeKey: false,
        streetAddressLine1: false,
        streetAddressLine2: false,
        city: false,
        state: false,
        zipPostalCode: false,
        country: false,
      };
    },
    apifetch(query) {
      if (this.deposit.accountType === "Customers") {
        GeneralApiService.get(`Customer/search/${query}`).then((resp) => {
          this.customers = resp.data.data.items;
        });
      } else if (this.deposit.accountType === "Groups") {
        GeneralApiService.get(`Customer/search/${query}/group`).then((resp) => {
          this.customers = resp.data.data.groups;
          this.groups = resp.data.data.customers;
        });
      }
    },
    validateBVN(bvn) {
      if (bvn) {
        if (bvn.length != 11) {
          this.customerErrorForm.bvn = true;
          this.$emit(
            "alertWarning",
            "BVN cannot be more/less than 11 characters."
          );
          return true;
        } else {
          this.customerErrorForm.bvn = false;
          return false;
        }
      }
    },
    saveCustomerForm: async function (e) {
      e.preventDefault();
      if (this.validateCustomerForm()) {
        if (this.requiredBVN && !this.customerForm.bvn) {
          this.$emit("alertWarning", "BVN field is required.");
          return;
        }
        if (this.validateBVN(this.customerForm.bvn)) {
          return;
        }
        if (!this.customerForm.customerEmailAddress && this.requiredEmail) {
          this.$emit("alertWarning", "Email field is required.");
          return;
        }
        if (this.customerErrorForm.customerEmailAddress) {
          this.$emit("alertWarning", "Please provide a valid email.");
          return;
        }
        if (this.requiredEmployer && !this.customerForm.employeeKey) {
          this.$emit("alertWarning", "Employer is required.");
          return;
        }
        let check = false;
        if (this.customerForm.identificationDocuments.length > 0) {
          this.customerForm.identificationDocuments.forEach((item) => {
            if (!item.documentId) {
              check = true;
              item.documentIdErr = true;
            } else {
              item.documentIdErr = false;
            }
            if (!item.validUntil) {
              check = true;
              item.validUntilErr = true;
            } else {
              item.validUntilErr = false;
            }
          });
        }
        if (check) {
          this.$emit(
            "alertWarning",
            "Documents ID and Valid Unit are required."
          );
          return;
        }
        this.isSaving = true;
        let isValidate = false;
        try {
          this.customerForm.customFieldValues = [];
          let cForm = [];
          if (this.customFields.length > 0) {
            this.customFields.forEach((formSet) => {
              formSet.customFields.forEach((form) => {
                let required = form.customFieldLinks.find(
                  (item) =>
                    item.entityLinkedKey === this.customerForm.customerTypesKey
                );
                if (!form.customFieldValue && required && required.isRequired) {
                  this.$emit(
                    "alertWarning",
                    `Some custom field value are required.`
                  );
                  form.customFieldNameErr = true;
                  isValidate = true;
                  return;
                } else {
                  form.customFieldNameErr = false;
                }
                if (form.customFieldValue) {
                  let key = form.customFieldKey;

                  let value = "";

                  if (form.dataType.toLowerCase() === "group link") {
                    value = form.customFieldValue.groupKey;
                  } else if (form.dataType.toLowerCase() === "user link") {
                    value = form.customFieldValue.userKey;
                  } else if (form.dataType.toLowerCase() === "customer link") {
                    value = form.customFieldValue.customerKey;
                  } else {
                    value = form.customFieldValue;
                  }

                  const formValObj = {
                    // name: form.customFieldName,
                    customFieldKey: key,
                    customFieldValue: value,
                    scoreAmount: 0,
                    indexInList: 0,
                    customFieldSetGroupIndex: 0,
                  };
                  cForm.push({ ...formValObj });

                  this.customerForm.customFieldValues = cForm;
                }
              });
            });
          }
          if (isValidate) {
            this.isSaving = false;
            return;
          }
          if (this.customerForm.identificationDocuments.length) {
            const identificationDocuments =
              this.customerForm.identificationDocuments.map((item) => ({
                attachments: item.attachments,
                documentId: item.documentId,
                documentType: item.documentType,
                documentIdTemplate: item.documentIdTemplate,
                identificationDocumentTemplateKey:
                  item.identificationDocumentTemplateKey,
                issuingAuthority: item.issuingAuthority,
                validUntil: item.validUntil,
              }));
            this.customerForm.identificationDocuments = [
              ...identificationDocuments,
            ];
          }
          const res = await customerService.createCustomer(this.customerForm);
          if (res.data.status) {
            document.getElementById("customerFormCloseButton").click();
            this.isSaving = false;
            this.$emit("alertSuccess", res.data.message);
            this.clearCustomCustomer();
            this.pushToCustomerPage(res.data.data);
            this.limitValue = "";
          } else {
            this.isSaving = false;
            this.$emit("alertWarning", res.data.message);
          }
        } catch (e) {
          this.isSaving = false;
          const error = e?.response?.data?.message;
          if (e.response.status == 409) {
            this.$emit("alertWarning", e.response.data);
          } else {
            this.$emit("alertWarning", error);
          }
        }
      } else {
        this.$emit("alertWarning", "Some form fields are required.");
      }
    },
    async getIdTemplates() {
      try {
        this.isLoading = true;
        const res = await idTemplatesService.get({
          pageSize: 100000,
          pageIndex: 1,
        });
        this.idTemplates = res.data.data.items;
        this.isLoading = false;
      } catch (e) {
        const error = e.response.data.message;
        if (error) return this.$emit("alertWarning", error);
      }
    },
    isDefault(field) {
      let check = field.customFieldLinks.find(
        (link) => link.entityLinkedKey === this.deposit.product.id
      );
      if (check) {
        return check.isDefault;
      }
      return false;
    },
    addIdTemplateToForm(form) {
      if (form == "Customer") {
        let templateKey = this.customerForm.selectedIdTemplate;
        let idtemp = this.idTemplates.find(
          (x) => x.identificationDocumentTemplateKey == templateKey
        );
        if (idtemp != null) {
          let toPush = {};
          Object.assign(toPush, idtemp);
          toPush.documentId = "";
          toPush.validUntil = "";
          toPush.filename = "";
          toPush.fileSize = 0;
          toPush.showDoc = false;

          this.customerForm.identificationDocuments.push(toPush);
        }
      }
    },
    idTemplateRemove(form, index) {
      if (form == "Customer") {
        this.customerForm.identificationDocuments.splice(index, 1);
      }
    },
    captureCustomFieldValues() {
      let fieldValues = [];
      this.customFields.forEach((formSet) => {
        formSet.customFields.forEach((form) => {
          let key = form.customFieldKey;
          let value = document.getElementById(key).value;

          const formValObj = {
            customFieldKey: key,
            value,
          };
          fieldValues.push(formValObj);
        });
      });
      return fieldValues;
    },
    validateBVNRequest() {
      if (this.limitValue.length === 11) {
        this.isDeleting = true;
        GeneralApiService.post(`Bvn/ValidateBvn?bvn=${this.limitValue}`)
          .then((res) => {
            this.customerForm.hasValidateBvn = true;
            this.bvnInfo = res.data.data.data;
            this.isDeleting = false;
          })
          .catch(() => {
            this.closeModal = true;
            this.isDeleting = false;
          });
      }
    },
    async getCustomerTypes() {
      try {
        this.$store.dispatch(GET_CUSTOMER_TYPES);
      } catch (e) {
        const error = e.response.data.message;
        this.$emit("alertWarning", error);
      }
    },
    generateCustomerId(key) {
      this.customerForm.uniqueCustomerId = "";
      GeneralApiService.get("Customer/GeneratedCustomersUniqueId", key)
        .then((response) => {
          this.customerForm.uniqueCustomerId = response.data.data;
        })
        .catch(() => {
          // console.log(err)
          this.customerForm.uniqueCustomerId = null;
        });
    },
    checkCustomFieldAvail(customField) {
      if (this.customerForm.customerTypesKey !== null) {
        let visible;
        visible = customField.customFieldLinks.find(
          (x) => x.entityLinkedKey == this.customerForm.customerTypesKey
        );
        return visible ? visible.isDefault : false;
      } else {
        return false;
      }
    },
    async getCustomFormFields(type, key) {
      this.customFields = [];
      try {
        if (type === "Customers" && key !== null) {
          this.requiredBVN = this.customerClientTypes.find(
            (item) => key === item.customerTypeKey
          ).requireBvn;
          this.requiredEmployer = this.customerClientTypes.find(
            (item) => key === item.customerTypeKey
          ).requireEmployer;
          this.requiredEmail = this.customerClientTypes.find(
            (item) => key === item.customerTypeKey
          ).requireEmailAddress;
          this.generateCustomerId(key);
        } else if (type === "Groups" && key !== null) {
          this.generateGroupId(key);
          this.requiredEmail = this.groupTypes.find(
            (item) => key === item.customerTypeKey
          ).requireEmailAddress;
        }
        this.isLoading = true;
        const model = {
          customFieldSetType: type,
          entityLinkedKey: key,
        };
        const res = await fieldSetService.getFormFieldsByType(model);
        this.customFields = res.data.data.items;
        this.isLoading = false;
      } catch (e) {
        const error = e.response.data.message;
        this.$emit("alertWarning", error);
      }
    },
    getCustom(customerTypesKey) {
      if (customerTypesKey) {
        return this.getCustomFormFields("Customers", customerTypesKey);
      }
      return;
    },
    async getBranches() {
      try {
        this.isLoading = true;
        const res = await branchesService.get({
          branchName: null,
          branchState: "Active",
          sortColumn: "",
          isDescending: false,
          pageIndex: 1,
          pageSize: 100,
        });
        this.allBranches = res.data.data.items;
        this.isLoading = false;
      } catch (e) {
        const error = e.response.data.message;
        this.$emit("alertWarning", error);
      }
    },
    updateEmployer() {
      this.customerForm.employeeKey = this.employer.employerKey;
    },
    async getCentres() {
      this.allCentres = [];
      try {
        this.isLoading = true;
        const res = await centresService.get({
          centreName: null,
          centreStatus: "Active",
          pageIndex: 1,
          pageSize: 100,
          sortColumn: "",
          isDescending: false,
        });
        this.allCentres = res.data.data.items;
        this.isLoading = false;
      } catch (e) {
        const error = e.response.data.message;
        this.$emit("alertWarning", error);
      }
    },
    getAllEmployers() {
      const paged = {
        pageIndex: 1,
        pageSize: 99999,
      };

      GeneralApiService.post("Employer/Employers", paged)
        .then((res) => res.data.data.items)
        .then((res) => {
          this.allEmployers = res;
        });
    },
  },
  watch: {
    show(change) {
      if (change) {
        this.getIdTemplates();
        this.getCustomerTypes();
        this.getBranches();
        this.getCentres();
        this.getAllEmployers();
        this.getInternalControl();
        this.getAllCustomers();
        this.getAllUsers();
      }
    },
    customerTypesKey(change) {
      if (change) {
        this.getCustom(this.customerTypesKey);
      }
    },
  },
  filters: {
    base64ToImg: function (value) {
      const imgUrl = "data:image/jpg;base64," + value;
      return imgUrl;
    },
    kb(val) {
      return Math.floor(val / 1024);
    },
    moment: function (date) {
      return date != null ? moment(date).format("Do-MMM-YYYY") : "";
    },
  },
  computed: {
    ...mapState({
      allCustomerTypes: (state) => state.deposit?.customerTypes?.data || [],
      customerTypes: (state) => state.deposit?.customerTypes?.data || [],
      customerClientTypes: (state) => state.deposit?.customerTypes?.data?.items,
      sortCustomerType() {
        let sorted = this.customerClientTypes?.slice().sort((a, b) => {
          let textA = a.clientTypeName.toUpperCase();
          let textB = b.clientTypeName.toUpperCase();
          return textA < textB ? -1 : textA > textB ? 1 : 0;
        });

        return sorted;
      },
      sortedUsers() {
        const userArray = this.allUsers;
        return userArray.sort((a, b) => a.name.localeCompare(b.name));
      },
      sortedCustomers() {
        const userArray = this.allActiveCustomers;
        return userArray.sort((a, b) =>
          `${a.customerFirstName} ${a.customerLastName}`.localeCompare(
            `${b.customerFirstName} ${b.customerLastName}`
          )
        );
      },
      sortedEmployers() {
        const employerArray = this.allEmployers;
        return employerArray.sort((a, b) => a.name.localeCompare(b.name));
      },
    }),
  },
};
</script>
